import { Injectable } from '@angular/core';
import { BaseUser } from '../models';
import { ElasticService } from './elastic.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserCacheService {
  cache: { [key: string]: BaseUser } = {};

  constructor(private elasticService: ElasticService) {}

  addToCache = (userId: string, user?: BaseUser) =>
    new Promise(resolve => {
      if (user) {
        this.cache[userId] = user;
        resolve(this.cache[userId]);
      } else {
        this.elasticService
          .query(
            'users',
            {
              query: {
                match: {
                  _id: userId,
                },
              },
            },
            environment.elasticPrefixBase
          )
          .then(res => {
            if (res.hits.hits.length > 0) {
              this.cache[userId] = res.hits.hits[0]._source;
              resolve(this.cache[userId]);
            }
          });
      }
    });
}
